// .title {
// 	position: absolute;
// 	width: 428px;
// 	height: 47px;
// 	left: 80px;
// 	top: 86px;

// 	font-family: Roboto;
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 36px;
// 	line-height: 28px;

// 	/* or 78% */
// 	letter-spacing: 0.01em;

// 	color: #000000;
// }

.appbar_title {
	// position: absolute;
	width: 428px;
	height: 50px;
	left: 80px;
	top: 0px;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;

	/* or 117% */
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;

	color: #ffffff;
}
