@import 'index.scss';

.row {
	padding: 0.5em;
	.MuiFormLabel-root {
		font-weight: bold;
		color: black;
		font-size: 1.4em;
	}
}

.row-major {
	background-color: $third-color;
}
