// .description {
// 	position: absolute;
// 	width: 465px;
// 	height: 73px;
// 	left: 80px;
// 	top: 139px;

// 	font-family: Roboto;
// 	font-style: normal;
// 	font-weight: normal;
// 	font-size: 13px;
// 	line-height: 18px;

// 	/* or 138% */

// 	color: #363636;
// }

// // .divider {
// // 	position: absolute;
// // 	width: 1279.5px;
// // 	height: 0px;
// // 	left: 81px;
// // 	top: 279px;

// // 	/* GREY KUB */
// // 	border: 1px solid #bbbbbb;
// // }

.tutorial_button {
	border-radius: 200;
}
