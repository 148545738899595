* {
	font-family: 'Roboto';
}

app {
	background-color: rgb(221, 216, 216);
}

$main-color: #3078e4;
$third-color: #9bbfdf;
