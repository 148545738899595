@import 'index.scss';

.name {
	line-height: 28px;
}

.email {
	line-height: 25px;
	margin-bottom: 20px;
}

.cards {
	margin-top: 20px;
}

.card {
	min-height: 27em;
}

.card-title {
	color: $main-color;
	display: inline;
}

.git-link {
	text-decoration: none;
	color: $main-color;
}
